// @flow

import type {ReduxActionType} from "../../flow/ReduxActionType";

/**
 * Load invoices
 * @type: Action
 */
export const invoicesLoad = (account_id: number): ReduxActionType => ({
    type: 'INVOICES_LOAD',
    payload: account_id,
});

/**
 * Invoices loaded
 * @type: Event
 */
export const invoicesLoaded = (invoices: []): ReduxActionType => ({
    type: 'INVOICES_LOADED',
    payload: invoices,
});
