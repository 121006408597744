// @flow

import type {ReduxActionType} from "../../flow/ReduxActionType";

/**
 * Load application
 * @type: Action
 */
export const appLoad = (domain_id): ReduxActionType => ({
    type: 'APP_LOAD',
    payload: domain_id,
});

/**
 * Rule loaded
 * @type: Event
 */
export const appLoaded = ({user, domains, locales, accounts, roles}): ReduxActionType => ({
    type: 'APP_LOADED',
    payload: {user, domains, locales, accounts, roles}
});

/**
 * Change domain selected
 * @type: Action
 */
export const appChangeDomain = (domain: number): ReduxActionType => ({
    type: 'APP_CHANGE_DOMAIN',
    payload: domain,
});

/**
 * Change domain selected
 * @type: Action
 */
export const appUserDisconnect = (): ReduxActionType => ({
    type: 'APP_USER_NOT_CONNECTED',
    payload: null,
});

export const appDomainsLoad = (): ReduxActionType => ({
    type: 'APP_DOMAINS_LOAD',
    payload: null,
});

export const appDomainsLoaded = (domains: []): ReduxActionType => ({
    type: 'APP_DOMAINS_LOADED',
    payload: {domains},
});

export const appMessageThrow = (message, type): ReduxActionType => ({
    type: 'APP_MESSAGE_THROW',
    payload: {message, type},
});

export const appMessageThrown = (): ReduxActionType => ({
    type: 'APP_MESSAGE_THROWN',
    payload: null,
});

export const appRedirect = (redirection): ReduxActionType => ({
    type: 'APP_REDIRECT',
    payload: redirection,
});

export const appRedirectWithQuery = (redirect: string, query: string): ReduxActionType => ({
    type: 'APP_REDIRECT_WITH_QUERY',
    payload: {redirect, query},
});

export const appRedirected = (): ReduxActionType => ({
    type: 'APP_REDIRECTED',
    payload: null,
});

export const appFrontendEditor = (domain_id: number, language: string = ''): ReduxActionType => ({
    type: 'APP_FRONTEND_EDITOR',
    payload: {domain_id, language},
});

export const appUserUpdated = (user): ReduxActionType => ({
    type: 'APP_USER_UPDATED',
    payload: user,
});

export const appDomainUpdated = (domain): ReduxActionType => ({
    type: 'APP_DOMAIN_UPDATED',
    payload: domain,
});

export const appEntriSuccess = (success): ReduxActionType => ({
    type: 'APP_ENTRI_SUCCESS',
    payload: success,
});