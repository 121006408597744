// @flow

import type {ReduxActionType} from "../../flow/ReduxActionType";

/**
 * Load subscriptions
 * @type: Action
 */
export const subscriptionPlansLoad = (): ReduxActionType => ({
    type: 'SUBSCRIPTION_PLANS_LOAD',
    payload: null,
});

/**
 * Billing loaded
 * @type: Event
 */
export const subscriptionPlansLoaded = (data: {}): ReduxActionType => ({
    type: 'SUBSCRIPTION_PLANS_LOADED',
    payload: data,
});

/**
 * Save a subscriptions
 * @type: Action
 */
export const updateDomainSubscription = (id, plan): ReduxActionType => ({
    type: 'DOMAIN_SUBSCRIPTION_UPDATE',
    payload: {id, plan},
});

/**
 * Redirect to Stripe portal
 * @type: Action
 */
export const redirectPortal = (id): ReduxActionType => ({
    type: 'DOMAIN_SUBSCRIPTION_REDIRECT_PORTAL',
    payload: {id},
});

/**
 * Request failed
 * @type: Event
 */
export const subscriptionPlansRequestFailed = (): ReduxActionType => ({
    type: 'SUBSCRIPTION_PLANS_REQUEST_FAILED',
    payload: null,
});
