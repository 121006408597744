// @flow
import type {ReduxActionType} from "../../flow/ReduxActionType";
import type {DomainType} from "../../flow/DomainType";

const INITIAL_STATE = {
    loading: true,
    connected: false,
    domains: [],
    domain: null,
    locales: [],
    user: null,
    message: null,
    redirect: null,
    query: null,
    accounts: [],
    roles: [],
    entri_success: false,
};

type State = { type?: string };

const App = (state: State = INITIAL_STATE, action: ReduxActionType) => {
    switch (action.type) {
        case 'APP_LOADED':
            return {
                ...state,
                loading: false,
                connected: true,
                ...action.payload,
                accounts: state.accounts,
                domain: null
            };

        case 'APP_USER_NOT_CONNECTED':
            return {...state, loading: false, connected: false, user: null};

        case 'APP_CHANGE_DOMAIN':
            return {
                ...state,
                domain: state.domains.length && action.payload
                    ? state.domains.find((d) => d.id === parseInt(action.payload)) || null
                    : null
            };

        case 'APP_DOMAINS_LOADED':
            return {...state, loading: false, domains: action.payload.domains};

        case 'APP_MESSAGE_THROW':
            return {...state, message: {message: action.payload.message, type: action.payload.type}};

        case 'APP_MESSAGE_THROWN':
            return {...state, message: null};

        case 'APP_REDIRECT':
            return {...state, redirect: action.payload};

        case 'APP_REDIRECT_WITH_QUERY':
            return {...state, redirect: action.payload.redirect, query: action.payload.query};

        case 'APP_REDIRECTED':
            return {...state, redirect: null, query: null};

        case 'APP_USER_UPDATED':
            return {...state, user: {...state.user, ...action.payload}};

        case 'APP_DOMAIN_UPDATED':
            const updatedDomain: DomainType = action.payload;
            return {...state, domains: state.domains.map((d: DomainType) => (d.id === updatedDomain.id ? updatedDomain : d))};

        case 'APP_ENTRI_SUCCESS':
            return {...state, entri_success: action.payload};

        case 'ACCOUNTS_LOADED':
            return {...state, accounts: action.payload};

        case 'ACCOUNT_ADDED':
            return {...state, accounts: [...state.accounts, action.payload]};

        default:
            return {...state};
    }
};

export default App;
