// @flow
import React from 'react';
import { Link } from 'react-router-dom';
import { Row, Col, Breadcrumb, BreadcrumbItem } from 'reactstrap';
import HelpLinkIcon from "./HelpLinkIcon";
import PropTypes from 'prop-types';
import TooltipItem from "./TooltipItem";

type PageTitleProps = {
    breadCrumbItems: PropTypes.object,
    domain_id: number,
    title: string,
    help_link?: string
};

/**
 * PageTitle
 */
const PageTitle = (props: PageTitleProps) => {
    return (
        <Row>
            <Col>
                <div className="page-title-box">
                    <div className="page-title-right">
                        <Breadcrumb>
                            <BreadcrumbItem>
                                <Link to={(props.domain_id ? `/domain/${props.domain_id}/dashboard` : '/')}>Linguise</Link>
                            </BreadcrumbItem>
                            {props.breadCrumbItems.map((item, index) => {
                                return item.active ? (
                                    <BreadcrumbItem active key={index}>
                                        {item.label}
                                    </BreadcrumbItem>
                                ) : (
                                    <BreadcrumbItem key={index}>
                                        <Link to={item.path}>{item.label}</Link>
                                    </BreadcrumbItem>
                                );
                            })}
                        </Breadcrumb>
                    </div>
                    <h4 className="page-title">
                        {props.title}
                        {props.help_link && (
                            <>
                                <HelpLinkIcon href={props.help_link} title={props.title} id={props.id} />
                                <TooltipItem content={props.title} target={props.id} placement="top" />
                            </>
                        )}
                    </h4>
                </div>
            </Col>
        </Row>
    );
};

export default PageTitle;
